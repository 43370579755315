/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import style from '../../register/style';

import {
  PerformantTextField,
  PerformantDropdown,
  Loader,
} from '../../../components/atoms';
import { moveStudent } from '../../../store/actions/getStudent';
import {
  getStudentSectionsService,
  getCourseLocationService,
  getLocationsByCourseId,
  getLocationsByRegionService,
} from '../../../store/services/auth';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import userRoles from '../../../constant/userRoles';
import useStudent from '../../../custom-hooks/useStudent';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';

function SwapCourseLocation(props) {
  const {
    swapCourseLocationInfo,
    setCustomForm,
    setSwapCourseOrLocation,
    refreshStudentsData,
    isChangeLogVisible = true,
    courseData,
    setDisableMovestudent,
    isSectionChanged,
  } = props;
  const { t } = useTranslation();
  const selectedStudentData = useStudent();
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const [locationOptions, setLocationOptions] = useState(
    selectedStudentData?.locations,
  );
  const [sectionOptions, setSectionOptions] = useState([]);
  const [classLevelOption, setClassLevelOptions] = useState(
    courseData || selectedStudentData?.courses,
  );
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMText, setErrorMText] = useState('');
  const userId = getLocalStorage('userId');
  const registerClasses = useStyles(style)();
  const textField = (
    label,
    id,
    type,
    handleChange,
    onBlur,
    value,
    error,
    required = true,
    disable = false,
    allowSpecialCharacter = false,
  ) => (
    <PerformantTextField
      label={label}
      id={id}
      required={required}
      name={id}
      type={type}
      value={value}
      onBlur={onBlur}
      error={error}
      onChange={(e) => {
        handleChange(e);
      }}
      disabled={disable}
      allowSpecialCharacter={allowSpecialCharacter}
    />
  );
  const classes = useStyles(styles)();
  const swapCourseValidations = Yup.object({
    studentName: Yup.string(t('STUDENT_NAME_REQUIRED'))
      .required(t('STUDENT_NAME_REQUIRED'))
      .typeError(t('STUDENT_NAME_REQUIRED')),
    academicYear: Yup.string(t('ACADEMIC_YEAR_REQUIRED'))
      .required(t('ACADEMIC_YEAR_REQUIRED'))
      .typeError(t('ACADEMIC_YEAR_REQUIRED')),

    courseFrom: Yup.string(t('COURSE_FROM_REQUIRED'))
      .required(t('COURSE_FROM_REQUIRED'))
      .typeError(t('COURSE_FROM_REQUIRED')),

    courseTo: Yup.string(t('COURSE_TO_REQUIRED'))
      .required(t('COURSE_TO_REQUIRED'))
      .typeError(t('COURSE_TO_REQUIRED')),

    locationFrom: Yup.string(t('LOCATION_FROM_REQUIRED'))
      .required(t('LOCATION_FROM_REQUIRED'))
      .typeError(t('LOCATION_FROM_REQUIRED')),

    locationTo: Yup.string(t('LOCATION_TO_REQUIRED'))
      .required(t('LOCATION_TO_REQUIRED'))
      .typeError(t('LOCATION_TO_REQUIRED')),
    sectionTo: Yup.string(t('SECTION_TO_REQUIRED'))
      .required(t('SECTION_TO_REQUIRED'))
      .typeError(t('SECTION_TO_REQUIRED')),
    changeLogs: Yup.string(t('CHANGE_LOG_REQUIRED'))
      .required(t('CHANGE_LOG_REQUIRED'))
      .typeError(t('CHANGE_LOG_REQUIRED')),
  });

  const dispatch = useDispatch();
  const validationSchema = swapCourseValidations;
  const formik = useFormik({
    initialValues: {
      studentName: swapCourseLocationInfo?.studentName,
      academicYear: swapCourseLocationInfo?.acedemicYear,
      courseFrom: swapCourseLocationInfo?.courseFrom,
      courseTo: swapCourseLocationInfo?.courseTo,
      locationFrom: swapCourseLocationInfo?.locationFrom,
      locationTo: swapCourseLocationInfo?.locationTo,
      sectionFrom: swapCourseLocationInfo?.sectionFrom,
      sectionTo:
        sectionOptions?.length && swapCourseLocationInfo?.sectionTo
          ? swapCourseLocationInfo?.sectionTo
          : '',
      changeLogs: swapCourseLocationInfo?.changeLogs,
    },
    validationSchema,
    onSubmit: (values) => {
      const callback = () => {
        setSwapCourseOrLocation(false);
        if (refreshStudentsData) {
          setTimeout(() => {
            refreshStudentsData();
          }, 1000);
        }
        setLoading(false);
      };
      setLoading(true);
      const payloadObj = {
        studentId: swapCourseLocationInfo?.studentId,
        changeLog: values?.changeLogs,
        logMessage: values?.changeLogs,
        isSectionChanged,
        isCourseSwapp: false,
        userId,
      };
      if (values?.courseTo !== swapCourseLocationInfo?.courseTo) {
        payloadObj.courseId = values?.courseTo;
        payloadObj.isCourseSwapp = true;
      } else {
        payloadObj.courseId = swapCourseLocationInfo.courseTo;
      }
      if (values?.locationTo !== swapCourseLocationInfo?.locationTo) {
        payloadObj.locationId = values?.locationTo;
      } else {
        payloadObj.locationId = swapCourseLocationInfo.locationTo;
      }
      if (
        values?.sectionTo === 0
        || values?.sectionTo === ''
        || values?.sectionTo?.length <= 1
      ) {
        payloadObj.createNewSection = true;
      } else if (values?.sectionTo !== swapCourseLocationInfo?.sectionTo) {
        payloadObj.sectionId = values.sectionTo;
      }
      dispatch(
        moveStudent(
          payloadObj,
          setShowError,
          setErrorMText,
          setLoading,
          callback,
        ),
      );
    },
  });

  const checkIfValuesAreChanged = () => {
    const hasLength = formik?.values?.locationTo?.length
      && formik?.values?.courseTo?.length
      && (formik.values.sectionTo === ''
        || formik?.values?.sectionTo !== null
        || formik?.values?.sectionTo !== undefined);

    let noFieldChange = true;

    const noChangeInLocation = formik?.values?.locationTo === swapCourseLocationInfo?.locationTo;

    const noChangeInCourse = formik?.values?.courseTo === swapCourseLocationInfo?.courseTo;

    const noChangeInSection = swapCourseLocationInfo?.sectionTo
      ? formik?.values?.sectionTo === swapCourseLocationInfo.sectionTo
      : formik?.values?.sectionTo === sectionOptions?.[0]?.id;

    noFieldChange = hasLength
      ? noChangeInLocation && noChangeInCourse && noChangeInSection
      : true;

    return noFieldChange;
  };

  useEffect(() => {
    if (formik.values?.locationTo !== undefined && formik.values?.courseTo !== undefined) {
      const payload = {
        locationId: formik?.values?.locationTo,
        courseId: formik?.values?.courseTo,
        academicYear: localStorage.getItem('academicYear'),
      };
      getStudentSectionsService(payload)
        .then((res) => {
          const options = res?.data?.map((i) => ({
            id: i?.id || i?.section,
            name: i?.section,
          }));
          setSectionOptions(options);
        })
        .catch(() => {});
    }
    setCustomForm(formik);
  }, [formik?.values?.locationTo, formik?.values?.courseTo]);

  useEffect(() => {
    const flag = checkIfValuesAreChanged();
    setDisableMovestudent(flag);
  }, [
    formik?.values?.locationTo,
    formik?.values?.sectionTo,
    formik?.values?.courseTo,
  ]);
  useEffect(() => {
    const index = sectionOptions.findIndex(
      (obj) => obj.id === swapCourseLocationInfo?.sectionTo,
    );
    if (index > -1) {
      formik.setFieldValue('sectionTo', swapCourseLocationInfo?.sectionTo);
    } else {
      formik.setFieldValue('sectionTo', sectionOptions?.[0]?.id);
    }
  }, [sectionOptions]);

  useEffect(() => {
    const locationTo = formik?.values?.locationTo !== undefined ? formik?.values?.locationTo : swapCourseLocationInfo?.locationTo;
    if (locationTo !== undefined) {
      formik.setFieldValue('locationTo', locationTo);
    } else {
      formik.setFieldValue('locationTo', locationOptions?.[0]?.id);
    }
  }, [locationOptions]);
  useEffect(() => {
    if (userRole !== userRoles.REGION_COORDINATOR && formik.values?.locationTo !== undefined) {
      const payload = {
        locationId: formik?.values?.locationTo,
      };

      getCourseLocationService(payload)
        .then((response) => {
          const options = [];
          response.data.forEach((option) => {
            options.push(option.course);
          });
          setClassLevelOptions(options);
        })
        .catch(() => {});

      setCustomForm(formik);
    }
  }, [formik?.values?.locationTo]);

  useEffect(() => {
    if (userRole === userRoles.REGION_COORDINATOR) {
      getLocationsByRegionService()
        .then((res) => {
          setLocationOptions(res?.data);
        })
        .catch(() => {});
    } else {
      const courseId = formik?.values?.courseTo;
      getLocationsByCourseId(courseId)
        .then((res) => {
          setLocationOptions(res?.data?.locations);
        })
        .catch(() => {});
    }
    setCustomForm(formik);
  }, [formik?.values?.courseTo]);
  useEffect(() => {
    const flag = checkIfValuesAreChanged();
    setDisableMovestudent(flag);
    if (userRole === userRoles.LOCATION_COORDINATOR) {
      formik.values.changeLogs = 'Changed Section from Location Coordinator';
    }
    setCustomForm(formik);
  }, [formik?.values?.sectionTo]);

  const setPreviousFieldTouch = (key) => {
    const allFields = [
      'studentName',
      'academicYear',
      'courseFrom',
      'courseTo',
      'locationFrom',
      'locationTo',
      'sectionFrom',
      'sectionTo',
      'changeLog',
    ];
    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>{errorText}</span>
  ) : null);

  return (
    <Box>
      <FormikProvider value={formik}>
        <form
          name="tenantUserForm"
          noValidate
          autoComplete="off"
          className={`${registerClasses.form} ${classes.form}`}
        >
          <Grid container className={registerClasses.mainContainer}>
            <Grid container spacing={2} className={classes.innerContainer}>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={classes.tenantUserWidth}
              >
                {textField(
                  t('STUDENT_NAME'),
                  'studentName',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('studentName'),
                  formik.values.studentName,
                  getErrorText('studentName'),
                  true,
                  true,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth} ${classes.academicYear}`}
              >
                {textField(
                  t('ACADEMICYEAR'),
                  'academicYear',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('academicYear'),
                  formik.values.academicYear,
                  getErrorText('academicYear'),
                  true,
                  true,
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={`${classes.innerContainer}`}>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth}`}
              >
                {textField(
                  t('COURSE_FROM'),
                  'courseFrom',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('courseFrom'),
                  formik.values.courseFrom,
                  getErrorText('courseFrom'),
                  true,
                  true,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth} ${classes.dropDownSelect}`}
              >
                <PerformantDropdown
                  customSelectClass={classes.select}
                  shrink
                  labelId={t('COURSE_TO')}
                  label={`${t('COURSE_TO')}`}
                  id="courseTo"
                  name="courseTo"
                  value={formik.values.courseTo}
                  handleChange={formik.handleChange}
                  options={classLevelOption}
                  onBlur={() => setPreviousFieldTouch('courseTo')}
                  error={getErrorText('courseTo')}
                  required
                  disabled={
                    userRole === userRoles.REGION_COORDINATOR
                    || userRole === userRoles.LOCATION_COORDINATOR
                  }
                />
                {getErrorText('courseTo')}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={`${classes.innerContainer}`}>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth}`}
              >
                {textField(
                  t('LOCATION_FROM'),
                  'locationFrom',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('locationFrom'),
                  formik.values.locationFrom,
                  getErrorText('locationFrom'),
                  true,
                  true,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth} ${classes.dropDownSelect}`}
              >
                <PerformantDropdown
                  shrink
                  customSelectClass={classes.select}
                  label={`${t('LOCATION_TO')}`}
                  labelId={t('LOCATION_TO')}
                  id="locationTo"
                  name="locationTo"
                  value={formik.values.locationTo}
                  handleChange={formik.handleChange}
                  options={locationOptions}
                  onBlur={() => setPreviousFieldTouch('locationTo')}
                  error={getErrorText('locationTo')}
                  required
                  disabled={userRole === userRoles.LOCATION_COORDINATOR}
                />
                {getErrorText('locationTo')}
              </Grid>
            </Grid>

            <Grid container spacing={2} className={`${classes.innerContainer}`}>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth}`}
              >
                {textField(
                  t('SECTION_FROM'),
                  'sectionFrom',
                  'text',
                  formik.handleChange,
                  () => setPreviousFieldTouch('sectionFrom'),
                  formik.values.sectionFrom,
                  getErrorText('sectionFrom'),
                  true,
                  true,
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                className={`${classes.tenantUserWidth} ${classes.dropDownSelect}`}
              >
                <PerformantDropdown
                  customSelectClass={classes.select}
                  shrink
                  label={`${t('SECTION_TO')}`}
                  labelId={t('SECTION_TO')}
                  id="sectionTo"
                  name="sectionTo"
                  value={
                    formik.values.sectionTo?.length
                      ? formik.values.sectionTo
                      : ''
                  }
                  handleChange={formik.handleChange}
                  options={sectionOptions}
                  onBlur={() => setPreviousFieldTouch('sectionTo')}
                  error={getErrorText('sectionTo')}
                  required
                />

                {getErrorText('sectionTo')}
              </Grid>
              {isChangeLogVisible && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={`${classes.tenantUserWidth} ${classes.changeLogs}`}
                >
                  {textField(
                    t('CHANGE_LOG'),
                    'changeLogs',
                    'text',
                    formik.handleChange,
                    () => {
                      setPreviousFieldTouch('changeLogs');
                    },
                    formik.values.changeLogs,
                    getErrorText('changeLogs'),
                    true,
                    false,
                    true,
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
      {showError && (
        <Grid>
          <div className={classes.errorText}>{errorMText}</div>
        </Grid>
      )}
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );
}
export default SwapCourseLocation;
